body {
  font-family: 'Courier New', Courier, monospace;
  font-size: 12px;
  background-color: #ffffff;
  color: #000000;
  line-height: 1.6;
}

.App {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.token-data {
  margin-bottom: 20px;
  padding: 15px;
}

h2 {
  font-size: 16px;
  margin-bottom: 15px;
  padding-bottom: 5px;
}

/* Tabs container */
.tabs {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;
}

/* Individual tab button */
.tabs button {
  background: #fff;
  border: 1px solid #ddd;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add shadow */
}

.tabs button.active {
  background: #f1f1f1;
  border-bottom: 2px solid #848484;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Add a bit more shadow for the active tab */
}

.tabs button:hover {
  background: #f9f9f9;
}

/* Adjusting for mobile view */
@media (max-width: 768px) {
  .tabs {
    flex-direction: column;
  }

  .tabs button {
    margin-bottom: 0.5rem;
  }
}

.tabs {
  display: flex;
  margin-bottom: 15px;
  flex-wrap: wrap; /* Add this line for mobile */
}

.tabs button {
  background-color: #ffffff;
  border: 1px solid #000000;
  color: #000000;
  cursor: pointer;
  padding: 5px 10px;
  font-family: 'Courier New', Courier, monospace;
  font-size: 12px;
  margin-right: 5px;
  flex: 1; /* Add this line for mobile */
  margin-bottom: 5px; /* Add this line for mobile */
}

.tabs button.active {
  background-color: #000000;
  color: #ffffff;
}

.tab-content {
  border: 1px solid #000000;
  padding: 10px;
}

.data-item {
  margin-bottom: 5px;
}

.data-label {
  font-weight: bold;
  margin-right: 5px;
}

.data-value {
  word-break: break-all;
}

h4 {
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 5px;
  /* border-bottom: 1px dashed #000000; */
}

@media (max-width: 768px) {
  .tabs {
    flex-direction: column;
  }

  .tabs button {
    width: 100%;
    text-align: left;
  }

  .token-data {
    padding: 10px;
  }

  .tab-content {
    padding: 10px;
  }
}

/* Prevent zoom on input fields */
input[type='text'],
input[type='number'],
input[type='password'],
input[type='email'],
input[type='url'],
input[type='tel'],
input[type='search'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='month'],
input[type='week'],
input[type='time'],
input[type='color'],
select,
textarea {
  font-size: 12px;
}

/* Style the footer */
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center; /* Center text horizontally */
  padding: 2px 10px; /* Adjust padding to reduce height */
  background-color: rgb(24, 24, 24);
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1); /* Add shadow effect */
  z-index: 1000; /* Ensure footer is above other content */
}

.countdown {
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 0; /* Remove margin to reduce space */
}

.last-update {
  font-size: 12px;
  color: #ffffff;
  margin-top: 0; /* Remove margin to reduce space */
}

/* Add padding-bottom to the main content to avoid overlap with the fixed footer */
.main-content {
  padding-bottom: 40px; /* Adjust based on the height of your footer */
}
