.error-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.error-modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 4px;
  max-width: 300px;
  width: 90%;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  outline: none;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
}

.error-modal-close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #000;
}

.error-modal-body p {
  margin: 0;
  color: #000;
}
